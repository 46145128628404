<style scoped>
	ul{
		padding-left: 0;
		margin-bottom: 0;
	}
	a{
		text-decoration: none;
	}
	.hero-btn>a:hover{
        animation: hero-btn 2s ease-in infinite forwards;
    }
    @keyframes hero-btn {
        from{
            box-shadow: 0 0 0px yellow;
        }
        30%,100%{
            box-shadow: 0 0 10rem transparent;
        }
    }
	.owl-carousel.owl-hidden{
		opacity:1;
	}
	.qr-img{
		padding: 0 60px;
	}
	.header-area .logo img{
		width: 60%;
	}
</style>
<template>
	 <div class="wrapper">
		<!-- header area -->
		<header id="sticky-header">
			<div class="header-area">
				<div class="container">
					<div class="row al-center">
						<div class="col-lg-2 col-3">
							<div class="logo">
								<a href=""><img src="images/logo.png" alt="messing"></a>
							</div>
						</div>
						<div class="col-lg-10 col-9">
							<div class="main-manu">
								<ul>
									<li>
										<a @click="goAnchor('#trending-slot')" href="javascript:;">
											<img src="picture/popular.svg" alt="messing">热门精选
										</a>
									</li>
									<li><a @click="goAnchor('#game-casino')" href="javascript:;">全部游戏</a></li>
									<li><a @click="goAnchor('#card')" href="javascript:;">APP下载</a></li>
									<li><a @click="goAnchor('#contact')" href="javascript:;">联系我们</a></li>
								</ul>
							</div>
							<!-- Only Show On Mobile Devices -->
							<div class="mobila-manu-area">
								<div class="main-menu d-lg-none">
									<ul>
										<li>
											<a @click="goAnchor('#trending-slot')" href="javascript:;">
												热门
											</a>
										</li>
										<li><a @click="goAnchor('#game-casino')" href="javascript:;">全部游戏</a></li>
										<li><a @click="goAnchor('#card')" href="javascript:;">APP下载</a></li>
										<li><a @click="goAnchor('#contact')" href="javascript:;">联系</a></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		
		<!-- hero area -->
		<div class="hero-area">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="hero-all-content">
						<div class="hero-content">
							<div class="double-content">
								<h2 class="up">{{title1}}</h2>
								<h2 class="down">{{title1}}</h2>
							</div>
							<p v-html="titleWords1"></p>
							<div class="hero-btn">
								<a class="d-none d-sm-flex"  @click="goAnchor('#card')" href="javascript:;">点击下方按钮开启畅玩<i class="bi bi-arrow-right"></i></a>
								<a class="d-flex d-sm-none"  @click="goAnchor('#card')" href="javascript:;">点击畅玩<i class="bi bi-arrow-right"></i></a>
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<!-- trading slot -->
		<div id="trending-slot" class="trading-area">
			
			<div class="trading-tittle ps-3">
				<h6><i class="bi bi-fire"></i>热门精选</h6>
			</div>
			<div class="container">
				
				<div class="owl-carousel trending-slider">
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/trending1.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人轮盘</h2>
							<p>Roulette</p>
							<div class="total-people">
								<h4>在线人数</h4>
								<h2><i class="bi bi-fire"></i>{{Math.floor(Math.random()*(500-400)+400)}}</h2>
							</div>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/bac.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人百家</h2>
							<p>Baccarat</p>
							<div class="total-people">
								<h4>在线人数</h4>
								<h2><i class="bi bi-fire"></i>{{Math.floor(Math.random()*(9000-6000)+6000)}}</h2>
							</div>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/bull.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人牛牛</h2>
							<p>Classic Bull</p>
							<div class="total-people">
								<h4>在线人数</h4>
								<h2><i class="bi bi-fire"></i>{{Math.floor(Math.random()*(9000-5000)+5000)}}</h2>
							</div>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/dezhou.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人德州</h2>
							<p>Texas Poker</p>
							<div class="total-people">
								<h4>在线人数</h4>
								<h2><i class="bi bi-fire"></i>{{Math.floor(Math.random()*(5000-4000)+4000)}}</h2>
							</div>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/lucky.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>老虎机</h2>
							<p>slot machine</p>
							<div class="total-people">
								<h4>在线人数</h4>
								<h2><i class="bi bi-fire"></i>{{Math.floor(Math.random()*(5000-4000)+4000)}}</h2>
							</div>
						</div>
					</div>
					<div class="single-trending-item">
						<div class="trending-left">
							<img src="picture/touzi.jpg" alt="messing">
						</div>
						<div class="trending-right">
							<h2>真人骰子</h2>
							<p>Dice</p>
							<div class="total-people">
								<h4>在线人数</h4>
								<h2><i class="bi bi-fire"></i>{{Math.floor(Math.random()*(500-400)+400)}}</h2>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	 <!-- card area -->
	 <div class="card-area app-btn">
		<div class="container">
			<div id="card" class="cta-ara">
				<div  class="d-flex justify-content-center">
					<h2>Are you ready to play?</h2>
					<a @click="goLink(chatList[0].url)" href="javascript:;" class="join mt-2 mt-lg-4 ms-2 ms-lg-3"><i class="bi bi-chat-dots-fill me-1"></i>{{chatList[0].text}}</a>
					<a @click="goLink(chatList[1].url)" href="javascript:;" class="join mt-2 mt-lg-4 ms-2 ms-lg-3"><i class="bi bi-box-arrow-in-down me-1"></i>{{chatList[1].text}}</a>
				</div>
				
				<div class="cta-btn d-flex flex-wrap justify-content-center text-nowrap align-items-center">
					<h4 class="col-3 col-sm-4 col-lg-5">网络投注 ：</h4>
					<div class="col-9 col-sm-8 col-lg-7 d-flex flex-wrap">
						<a v-for="(item,i) in newsList" :key="item" @click="goLink(item.url)" href="javascript:;" class="ms-2 me-2 align-items-center">
							<i class="bi bi-hand-index me-1" :class="{'bi-browser-chrome':i==0,'bi-globe':i==1,'bi-globe-asia-australia':i==2}"></i>{{item.text}}
						</a>
					</div>
				</div>
				<div class="cta-btn d-none d-sm-flex flex-wrap justify-content-center text-nowrap align-items-center">
					<h4 class="col-3 col-sm-4 col-lg-5">电话投注 ：</h4>
					<div class="col-9 col-sm-8 col-lg-7 d-flex flex-wrap">
						<a v-for="(item,i) in phoneList" :key="item" @click="goLink(item.url)" href="javascript:;" class="ms-2 me-2 align-items-center">
							<i :class="{'bi-globe':i==0,' bi-globe-asia-australia':i==1}" class="bi bi-hand-index me-1"></i>{{item.text}}
						</a>
					</div>
				</div>
				<div class="cta-btn d-flex flex-wrap justify-content-center text-nowrap align-items-center">
					<h4 class="col-3 col-sm-4 col-lg-5">手机APP ：</h4>
					<div class="col-9 col-sm-8 col-lg-7 d-flex flex-wrap">
						<a v-for="(item,i) in phoneAppList" :key="item" @click="goLink(item.url)" href="javascript:;" class="ms-2 me-2 align-items-center">
							<i :class="{'bi-android2':i==0,'bi-apple':i==1}" class="bi bi-hand-index me-1"></i>{{item.text}}
						</a>
					</div>
				</div>
				<div class="cta-btn d-none d-sm-flex flex-wrap justify-content-center text-nowrap align-items-center">
					<h4 class="col-3 col-sm-4 col-lg-5">PC下载版：</h4>
					<div class="col-9 col-sm-8 col-lg-7 d-flex flex-wrap">
						<a v-for="(item,i) in windowsList" :key="item" @click="goLink(item.url)" href="javascript:;" class="ms-2 me-2 align-items-center">
							<i :class="{'bi-box-arrow-in-down':i==0,'bi-cast':i==1}" class="bi bi-hand-index me-1"></i>{{item.text}}
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- gaming area -->
	<div id="game-casino" class="gaming-area">
		<div class="container">
			<div class="all-collection-manu mb-4">
				<ul class="filter-project">
					<li class="active" data-filter="*">
						<img src="picture/all-slots.svg" alt="messing">&nbsp;
						<span>全部</span>  
					</li>
					<li data-filter=".black">
						<img src="picture/black-jack.svg" alt="messing">&nbsp;
						<span>真人游艺</span>
					</li>
					<li data-filter=".casino">
						<img src="picture/casino.svg" alt="messing">&nbsp;
						<span>电子竞技</span>
					</li>
					
				</ul>
			</div>
			<div class="all-collection-item">
				<div class="row project-list">
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 black pro">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/bac.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人百家</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 casino">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/lucky.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">老虎机</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/trending1.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人轮盘</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 casino">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/dezhou.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">电子德州</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/touzi.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人骰子</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/bull.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人牛牛</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/3gong.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人三公</a>
							</div>
						</div>
					</div>
					<div class="col-4 col-sm-12 col-lg-3 col-md-4 black">
						<div class="single-gaming-item">
							<div class="gaming-img overflow-hidden">
								<img src="picture/coin.jpg" alt="messing">
							</div>
							<div class="gaming-hover">
								<a class="demo">真人钢镚</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
 <!-- card area -->
 <div class="card-area">
    <div class="container">
        <div class="row card-item">
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card1.png" alt="messing">
                    </div>
                    <div class="card-content">
						<!-- <h2>移动APP</h2> -->
                        <p>{{cardList[0].text}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card2.jpg" alt="messing">
                    </div>
                    <div class="card-content">
                        <!-- <h2>H5游戏</h2> -->
                        <p>{{cardList[1].text}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 ps-lg-4 pe-lg-4">
                <div class="single-card-item">
                    <div class="cart-img h-100 overflow-hidden">
                        <img class="h-100" src="picture/card3.png" alt="messing">
                    </div>
                    <div class="card-content">
                        <!-- <h2>实时服务</h2> -->
                        <p>{{cardList[2].text}}</p>
                    </div>
                </div>
            </div>
        </div>
		<div class="deposite-area">
			<div class="deposit-qr">
				<div class="qr-bar">
					<p>您也可以选择扫码下载</p>
				</div>
				<div class="qr-tittle">
					<p>温馨提示： <span>{{appTip}}</span></p>
				</div>
				<div class="row d-flex justify-content-evenly mb-3 overflow-hidden">
					<div v-for="item in urlList" :key="item" class="qr-img col-6 col-sm-3" @click="goLink(item.url)" role="button">
						<vue-qr class="img-thumbnail rounded" :margin="10" :text="createQr(item.url)"></vue-qr>
						<span class="small" style="display: inherit;">{{item.text}}</span>
					</div>
				</div>
			</div>
		</div>
		
    </div>
 </div>
 <!-- footer area start -->
  <footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-4">
                <div class="logo-part">
                     <div class="logo">
                        <a href="javascript:;"> <img src="images/logo.png" alt="messing"></a>
                     </div>
                     <p>更多精彩游戏，尽在龙源国际！ <br> More exciting games, all at Longyuan International ! <br> 欢迎加入！</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="information-part">
                    <h4>联系方式</h4>
                    <ul>
                        <li v-for="item in telList" :key="item" >
							{{item.text}}：
							<a @click="goLink(item.url)" href="javascript:;">{{item.telNumberN}}</a>
						</li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-5 col-md-5">
                <div id="contact" class="contact-part">
                    <h4>{{problemTitle}}</h4>
                    <ul>
                        <li>{{problemCon1}}</li>
                        <li>{{problemCon2}}</li>
                    </ul>
                </div>

                <div class="contact-part">
                    <h4>在线客服</h4>
                    <ul>
                        <li>客服24小时在线，如有需要，请随时联系我们！</li>
                        <li><a @click="goLink(chatList[0].url)" href="javascript:;" class="join">
							<i class="bi bi-chat-dots-fill me-1"></i>{{chatList[0].text}}</a>
						</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  </footer>
  <!-- copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="row">
            <div class="copyright-wrap">
                <div class="left-copyright">
                    <p>{{footCopyRight}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
// @ is an alias to /src
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { useLink } from 'vue-router';

export default {
	name: 'HomeView',
	components: {
		vueQr
	},
	data(){
		return {
			isShowModal:true,
			title1:config.title1,
          	titleWords1:config.titleWords1,
			notice:config.notice,
			cardList:config.cardList,
			urlList:config.urlList,
			appTip:config.appTip,
			chatList:config.chatList,
			newsList:config.newsList,
			phoneList:config.phoneList,
			phoneAppList:config.phoneAppList,
			windowsList:config.windowsList,
			telList:config.telList,
			problemTitle:config.problemTitle,
			problemCon1:config.problemCon1,
			problemCon2:config.problemCon2,
			footCopyRight:config.footCopyRight
		}
	},
	mounted(){
		setTimeout(()=>{

			//  trendig slot area
			$('.trending-slider').owlCarousel({
				loop: true,
				autoplay: true,
				margin:20,
				smartSpeed: 1500,
				dots: false,
				nav: false,
				navText: ["<i class='bi bi-arrow-left''></i>", "<i class='bi bi-arrow-right''></i>"],
				responsive: {
					0: {
						items:1,
						nav: false,
					},
					600: {
						items: 1,
						nav: false,
					},
					768: {
						items: 3
					},
					992: {
						items: 3
					},
					1000: {
						items: 4
					},
					1920: {
						items: 4
					}
				}
			})
			// game isotope(home page)
			$(".project-list").isotope();
		
			$(".filter-project li").on('click', function(){
				$(".filter-project li").removeClass("active");
				$(this).addClass("active");
				var selector = $(this).attr("data-filter");
				$(".project-list").isotope({
					filter: selector,
					
				});
			});
			// card isotope(home page)
			$(".card-item").isotope();
	
			 // Meanmenu 
			$('#mobile-menu').meanmenu({
				meanMenuContainer: '.mobile-menu',
				meanScreenWidth: "991",
				meanMenuOpen: "<span></span> <span></span> <span></span>",
				onePage: false,
			});

			// sticky Header
			var wind = $(window);
			wind.on('scroll', function () {
				var sticky = $('#sticky-header');
				var scroll = wind.scrollTop();
				if (scroll < 100) {
					sticky.removeClass('sticky');
				} else {
					sticky.addClass('sticky');
				}
			});
		},400)
	},
	methods:{
		goLink(url){
			window.location.href=url;
		},
		goAnchor (selector) {
			document.querySelector(selector).scrollIntoView({
				behavior:"smooth"
			})
		},
		createQr(url){
			var title=new RegExp('Http','i');
			var result=title.test(url)
			if(result!=true){
				url=location.origin+"/#/"+url
				console.log(url) 
			}
			return url;
		}
    }
};
</script>
